<template>
  <div id="AreaChart">
    <apexchart width="95%" :height="height" ref="chart1" type="bar" :options="options" :series="series" class="chart-wrapper">
    </apexchart>	
  </div>
</template>

<!-- Centrar Chart --->

<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -15px;
}
</style>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';

export default {
  name: 'AreaChart',
  props: ['region','anio','height'],
  data() {
    return {
      options: {    
        chart: {
          id: 'ChartAreaHome',
          zoom: false,
          toolbar:{
              show:true
          },
          stacked: true,
        },
        plotOptions:{
          bar: {
            barWidth: '100%',
            dataLabels: {
            position: 'top'
           },
          }
        },
        xaxis: {
          type: "categories",
          categories: [],
          style: {
              colors: '#fff',
              fontSize: '12px',
              fontWeight: 550
          },
        },
        yaxis:{
          show: false,
          labels: {
              formatter: function (val) {
                  return val+'%'
              }
          }
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          offsetY:-20,
          offsetX:1,
          formatter: function(val,w){
            var index = w['dataPointIndex'];
            var data = w['w'].globals.series
            var totalData = data[0][index] + data[1][index]
            var porcentaje = parseFloat(((val / totalData)*100).toFixed(2));
            if(porcentaje != 0.00){
              return porcentaje+'%';
            }else{
              return '0%';
            }
          },
          style: {
            fontSize: '10px',
            colors: ['#000']
          },  
        },
        colors:["#E53935","#CCCCCC"],
        legend: {
            show:false
        },
        grid: {
          show:false
        },
        tooltip:{

          theme:'dark',
          custom: function({series, seriesIndex, dataPointIndex, w}) {

            function formatnumber(numero){
              var valor = numero.toLocaleString();
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }

              return stringFormat;
            }

            var index = dataPointIndex;
          
            var dato1 = series[0][index];
            var dato2 = series[1][index]+dato1;
            var categoria = w.globals.labels[index];
            
            var porcentaje = parseFloat(((dato1 / dato2)*100).toFixed(2));
            var tool = '<div style="margin: 0 auto;width: 300px; height: 200px;opacity:0.95;" >' +
                '<h4 class="font-weight-light text-left text-white" style="margin-top:10px; margin-left:15px">'+categoria.toUpperCase()+'</h4>'+
                '<h4 class="font-weight-light text-left" style="margin-top:10px;margin-left:15px">Gasto Acumulado:</h4>'+
                '<h3 class="font-weight-light text-left" style="margin-top:2px;margin-left:15px">$'+formatnumber(dato1)+' Millones</h3>'+
                '<h4 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px">Marco Evaluación:</h4>'+
                '<h3 class="font-weight-light text-left" style="margin-top:2px;margin-left:15px">$'+formatnumber(dato2)+' Millones</h3>'+
                '<h4 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px">Ejecución Presupuestaria:</h4>'+
                '<h3 class="font-weight-light text-left" style="margin-top:2px;margin-left:15px">'+porcentaje+'%</h3>'+

            '</div>';
            return tool;
          },  
        }
      },
      series: [],
        presupuesto_regional: [],
        g_acumulado: [],
        marco_evaluacion: [],
        meses_data: [],
      }
    },
    mounted() {
      
      this.crearData(this.anio)
    },
    computed:{
      itemRegion(){
        return this.region;
      },
      itemAnio(){
        return this.anio;
      }
    },
  watch: {
    itemRegion(){
      this.crearData(this.anio)
    },
    itemAnio(){
      this.crearData(this.anio)
    }
  },

  methods: {

    sortByMonth(arr) {  
      var months = ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"];
      arr.sort(function(a, b){
        return months.indexOf(a.mes) - months.indexOf(b.mes);
      })
    },

    organizar_prespuesto(data){
      var dato;
      var categorias = [];
      var marco = [];
      var gasto = [];
      var months = ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"];
      var month;
      let ultimo_marco;

      if(data.length !== 12){
        for (let i = 0; i < months.length; i++){
          month = months[i];
          if(data[i] !== undefined){
            let dato = data[i];
            if(!categorias.includes(dato.mes)){
              categorias.push(dato.mes);
            }
            gasto.push(dato.gasto_acumulado);
            marco.push(dato.marco_evaluacion-dato.gasto_acumulado);
            ultimo_marco = dato.marco_evaluacion;
          }
          else {
            categorias.push(month);
            gasto.push(0);
            marco.push(ultimo_marco);
          }
          
          
        }
        
          
        // for (month of months){
        //   for (dato of data){
        //     console.log(month, dato.mes)
        //     // if (month !== dato.mes){
        //     //   console.log(month)
        //     // }
        //   }
        
      }
      else {
        for (dato of data){
          if(!categorias.includes(dato.mes)){
            categorias.push(dato.mes);
          }
          gasto.push(dato.gasto_acumulado);
          marco.push(dato.marco_evaluacion-dato.gasto_acumulado); 
       }
      }



      

      return {'labels':categorias,'gasto':gasto,'marco':marco};
    },

    crearData(anio){
      axios.get(API_URL + "presupuesto_regional/?region="+this.region+"&anio="+anio)
      .then(response =>{
        this.presupuesto_regional = response.data.results;
        console.log(this.presupuesto_regional[0].region.nombre)
        this.sortByMonth(this.presupuesto_regional);
        var graphic_data = this.organizar_prespuesto(this.presupuesto_regional);
        this.marco_evaluacion = graphic_data.marco;
        this.g_acumulado = graphic_data.gasto;
        this.meses_data = graphic_data.labels;


        this.$refs.chart1.updateOptions({
          series: [
            {
              data: this.g_acumulado,
              name: 'Gasto Acumulado '
            },{
              data: this.marco_evaluacion,
              name: 'Marco Evaluación'
            }
          ],
          title:{
            text: 'Ejecución presupuestaria '+this.anio,
            align: 'left',
						style: {
							fontSize:  '24px',
							fontWeight:  'light',
							fontFamily:  undefined,
							color:  '#263238'
							},
              margin:-20,
              offsetY:5
          },
          subtitle:{
            text:this.presupuesto_regional[0].region.nombre,
            align: 'left',
						style: {
							fontSize:  '18px',
							fontWeight:  'light',
							fontFamily:  undefined,
							color:  '#263238'
							},
          },
          xaxis:{
            categories: this.meses_data,
             style: {
              colors: '#fff',
              fontSize: '15px',
              fontWeight: 560
            },
          }
                // tipo_dato: this.tipo_dato,
              // serie: this.serie
        });
      })
    }
  }
};
</script>
