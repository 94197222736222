<template>
  <div class='ArcPiechart'>
    <div id="chart">

        <apexchart type="donut" :options="chartOptions" :series="series"
        class="chart-wrapper"  width="100%"  :height="height" ref="chart1"
        ></apexchart>

      </div>
  </div>
</template>

<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: left;
    justify-content: left
}
</style>

<script>

export default {
  name: 'DonutPieChart',
  props: ['data','labels','colors','unidad','tipo_data','height'],
  data () {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
          toolbar:{
            show: false,
            offsetY: 10
          }
        },
        dataLabels:{
          formatter: function (val, opts) {
            if(val > 5)
              return val.toFixed(1)+'%'
            else
              return ''
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#fff'] 
          },
        },
        // colors:["#307C3F",'#032345','#FBC02D','#304FFE'],
        
        plotOptions: {
          pie:{
            donut: {
              size:'60%',
              labels: {
              show: true,
              name: {
                show: true,
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: undefined,
                offsetY: 0,
                formatter: function (val) {
                  return val
                }
              },
              value: {
                show: true,
                fontSize: '15px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                offsetY: 0,
                formatter: function (value,w) {

                  let unidad_tipo = w.config.tipo_data;



                  const tamanios = {
                    4:1,
                    5:2,
                    6:3,
                    7:1,
                    8:2,
                    9:3,
                    10:1,
                    11:2,
                    12:3
                  }
                  
                  const format_number = (string,tamanio) => {
                    let cabeza, resto;
                    let result;
                    let tamanio_cabeza = tamanios[tamanio]
                    cabeza = string.slice(0,tamanio_cabeza);
                    
                    if( tamanio >= 4 && tamanio < 7){
                      resto = string.slice(tamanio_cabeza,tamanio);
                   
                    }
                    else{
                      let resto_temp = string.slice(tamanio_cabeza,tamanio);
                      let largo_resto_temp = resto_temp.length;

                      let temp = ''
                      let c;
                      for(c in resto_temp){
                        let i = parseInt(c)+1
                        
                        if( i % 3 == 0){
                          let resto_result = i == largo_resto_temp ? resto_temp[i-1] : resto_temp[i-1]+'.'
                          temp += resto_result
                        }
                        else{
                          temp += resto_temp[i-1]
                        }
                      }
                      
                      resto = temp
                    }
                    
                    result = cabeza+'.'+resto;
                    
                    return result;
                  }

                  
                  let largo_value = value.length;
                
                   
                  let temp_string = value.split('.')
                  let no_decimal, decimal;
                  let result_number
                  
                  no_decimal = temp_string[0]
                  let largo_no_decimal = no_decimal.length;
                  
                  if(temp_string.length == 1){
                    result_number = format_number(no_decimal,largo_no_decimal)
                  }
                  else if(temp_string.length > 1){
                    decimal = temp_string[1]
                    result_number = format_number(no_decimal,largo_no_decimal)+','+decimal
                  }

                  let result_text = unidad_tipo == 'monto' ? '$'+result_number : result_number
                  return result_text
                }
              },
              total:{
                  show: true,
                  label: 'Total',
                  fontSize: this.height_chart > 350 ? '15px' : '12.5px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                  formatter: function (w) {

                    let unidad_tipo = w.config.tipo_data;
                    
                    let unidad = w.config.unidad

                    function formatCL(val){
                      var valor = val.toLocaleString();
                      var largo_string = valor.length
                      var i;
                      var stringFormat = ''; 
                      for(i of valor){
                        if( i === '.' ){
                          i = ',';
                          stringFormat+=i;
                        }
                        else if(i ===','){
                          i = '.';
                          stringFormat+=i;
                        }
                        else{
                          stringFormat+=i;
                        }
                      }
                      return stringFormat;
                    };
                    let total = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0);

                    let text = unidad_tipo == 'monto' ? '$'+formatCL(total) : formatCL(total);
                    return text
                  }
                }
              }
            }
          }
        },
        unidad:null,
        tipo_data:null,
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            function formatCL(val){
                      var valor = val.toLocaleString();
                      var largo_string = valor.length
                      var i;
                      var stringFormat = ''; 
                      for(i of valor){
                        if( i === '.' ){
                          i = ',';
                          stringFormat+=i;
                        }
                        else if(i ===','){
                          i = '.';
                          stringFormat+=i;
                        }
                        else{
                          stringFormat+=i;
                        }
                      }
                      return stringFormat;
                    };

            
            //Total segun valores de serie
            let total = series.reduce((a, b) => {
              return a + b
            }, 0);

            let unidad_tipo = w.config.tipo_data
            let unidad= w.config.unidad



            let value = series[seriesIndex];

            let porcentaje = ((value/total)*100).toFixed(2);
            
            let label = w.globals.labels[seriesIndex];

            let text = unidad_tipo == 'monto' ? '$'+formatCL(value) : formatCL(value);


            return '<div class="arrow_box" style="width:150px;height:120px;">' +
              '<div class="subtitle-1 ml-1  font-weight-medium">' + label + '</div>' +
              '<div class="body-1 ml-1">' +porcentaje+'%'+'</div>' +
              '<div class="subtitle-2 ml-1" style="margin-top:0px;">' +text  + '</div>' +
              '<div class="subtitle-2 ml-1" style="margin-top:0px;">' +unidad  + '</div>' +
              
              '</div>'
          }
        },  
      },
    }
  },
  computed: {
    updateData(){
      return this.data;
    }
  },

  watch: {

    updateData(){
      this.$refs.chart1.updateOptions({
        series: this.data,
        labels: this.labels,
        
        legend:{
          position: 'right',
          horizontalAlign: 'left', 
          fontSize: '16px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          offsetY:50,
          offsetX:30
        },
        colors: this.colors,
        unidad: this.unidad,
        tipo_data:this.tipo_data

      });
    }
  }

}
</script>

